html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

:root {
  --pomodoro: #FDE8E9;
  --shortBreak: hsl(48, 23%, 40%);
  --longBreak: hsl(105, 16%, 40%);
}

body {
  background-color: var(--pomodoro);
  transition: background-color 1s ease;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  user-select: none;
}

.timer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
}

.clock {
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 15rem;
  line-height: 1;
  display: flex;
  align-items: center;
  font-family: arial, sans-serif;
}

.mode-button {
  font-size: 16px;
  height: 28px;
  cursor: pointer;
  box-shadow: none;
  font-weight: 300;
  color: #1f221f;
  border: 1px solid transparent;
  margin: 0px;
  border-radius: 4px;
  padding: 2px 12px;
  background: none;
}

.mode-button.active {
  border-color: #1f221f;
}

.main-button {
  cursor: pointer;
  box-shadow: rgb(235, 235, 235) 0px 6px 0px;
  font-size: 22px;
  height: 55px;
  text-transform: uppercase;
  color: #fde8e9;
  font-weight: bold;
  width: 200px;
  background-color: #db5461;
  border-width: initial;
  border-style: none;
  margin: 20px 0px 0px;
  padding: 0px 12px;
  border-radius: 4px;
  transition: color 0.5s ease-in-out 0s;
}

.settings-form-button {
  cursor: pointer;
  box-shadow: #FDE8E9 2.5px 2.5px 0px;
  font-size: 8;
  height: 17px;
  text-transform: uppercase;
  color: #fde8e9;
  font-weight: bold;
  width: 70px;
  background-color: #db5461;
  border-width: initial;
  border-style: none;
  margin: 7px 0px 0px;
  padding: 0px 4px;
  border-radius: 1px;
  transition: color 0.5s ease-in-out 0s;
}

button:focus,
button:active {
  outline: none;
}

.main-button.active {
  transform: translateY(6px);
  box-shadow: none;
  outline: none;
}

@media screen and (max-width: 550px) {
  .clock {
    font-size: 8rem;
  }
}

.redo-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 30px;
  color: #333;
  margin-left: 20px;
}

.settings-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 34px;
  color: #333;
  margin-left: 20px;
}

.settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.settings-box {
  background-color: #9b82ff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
}

.settings-box h2 {
  margin-top: 0;
}

.settings-input {
  margin-bottom: 10px;
}

.settings-input label {
  display: block;
  margin-bottom: 5px;
}

.settings-input input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.settings-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.settings-buttons button {
  margin-left: 10px;
}

