.containerFlashcards {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 24px;
  color: #333;
}

.add-deck-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.deck-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.deck-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 4px;
  margin-bottom: 10px;
}

.deck-info {
  display: flex;
  align-items: center;
}

.deck-info h3 {
  font-size: 18px;
  margin-right: 10px;
}

.deck-info span {
  margin-right: 10px;
}

.deck-actions {
  display: flex;
  align-items: center;
}

.deck-actions button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.delete-deck-btn {
  background-color: transparent;
  color: #dc3545;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.card-container {
  margin-bottom: 20px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  perspective: 1000px;
  cursor: pointer;
  position: relative;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-back {
  transform: rotateY(180deg);
}

.card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.card p {
  margin-bottom: 0;
}

.card-buttons {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
}

.edit-btn,
.delete-btn {
  padding: 5px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}

.add-flashcard-btn,
.start-review-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.card-review {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.card-review-inner {
  margin-bottom: 20px;
}

.card-review h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.card-review p {
  margin-bottom: 20px;
}

.card-review-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.card-review-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
}

.card-review-end {
  text-align: center;
}

.card-review-end h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.card-review-end p {
  font-size: 18px;
  margin-bottom: 20px;
}

.card-review-end button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-flashcard-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.add-flashcard-container h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.add-flashcard-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.save-btn,
.cancel-btn {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.cancel-btn {
  background-color: #dc3545;
}

.deck-name-input-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.deck-name-input-container h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.deck-name-input-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.deck-name-input-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.deck-name-input-buttons button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.deck-name-input-buttons .cancel-btn {
  background-color: #dc3545;
}
