.app {
  background-color: #fde8e9;
  display: flex;
}

.side-bar {
  background-color: #9b82ff;
  width: 244px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.ButtonAskScio {
  border: solid 0.5px #1f2232;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}

.nav {
  border-top: solid 0.5px #1f2232;
  padding: 10px;
  margin: 10px;
}

.history {
  padding: 10px;
  margin: 10px;
  height: 100%;
}

.history li {
  list-style-type: none;
  padding: 15px 0;
  cursor: pointer;
}

.info {
  color: #1f2232;
  font-size: 11px;
  padding: 10px;
}

.bottom-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-container {
  position: relative;
  width: 100%;
  max-width: 650px;
}

input {
  width: 100%;
  border: none;
  font-size: 20px;
  background-color: #fff;
  padding: 12px 15px;
  border-radius: 5px;
  border: 2px solid #fff;
}

input:focus {
  outline: none;
}

#submit {
  position: absolute;
  right: 0;
  bottom: 15px;
  cursor: pointer;
}

.feed {
  width: 100%;
  padding: 0;
}

.feed li {
  display: flex;
  background-color: #fff;
  padding: 20px;
  margin: 20px 0;
}

.feed p {
  color: #9b82ff;
  font-size: 14px;
  text-align: left;
}

.feed p.role {
  min-width: 100px;
}

.pdf-upload-label {
  background-color: #f0f0f0;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}
